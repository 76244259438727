export const SETTINGS_TAB = {
  GENERAL: {
    index: 0,
    label: "General",
  },
  SPECIAL_HISTORY: {
    index: 1,
    label: "Special History",
  },
  USER_MANAGEMENT: {
    index: 2,
    label: "User Management",
  },
};
